/*
  Methods to update chart data
 */


function addHoverEvent(chart){
    var chart = chart;
    var canvasID = $(chart.canvas).attr('id');

    $(document).ready(function() {

        $('.chart-data').on('mouseenter', '.table.' + canvasID + ' .content tr', function () {
            elementHover(chart, $(this).data('idx'));
        }).on('mouseleave', '.table.' + canvasID + ' .content tr', function () {
            elementOut(chart);
        })

    });
}

function elementHover(chart, idx) {
    var meta = chart.getDatasetMeta(0),
        rect = chart.canvas.getBoundingClientRect(),
        point = meta.data[idx].getCenterPoint(),
        evt = new MouseEvent('mousemove', {
            clientX: rect.left + point.x,
            clientY: rect.top + point.y
        }),
        node = chart.canvas;
    node.dispatchEvent(evt);

}


function elementOut(chart) {
    var meta = chart.getDatasetMeta(0),
        rect = chart.canvas.getBoundingClientRect(),
        evt = new MouseEvent('mousemove', {
            clientX: rect.left,
            clientY: rect.top
        }),
        node = chart.canvas;
    node.dispatchEvent(evt);
}

function number_format (number, decimals, decPoint, thousandsSep) {

    number = (number + '').replace(/[^0-9+\-Ee.]/g, '')
    var n = !isFinite(+number) ? 0 : +number
    var prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
    var sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep
    var dec = (typeof decPoint === 'undefined') ? '.' : decPoint
    var s = ''

    var toFixedFix = function (n, prec) {
        var k = Math.pow(10, prec)
        return '' + (Math.round(n * k) / k)
            .toFixed(prec)
    }

    // @todo: for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.')
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || ''
        s[1] += new Array(prec - s[1].length + 1).join('0')
    }

    return s.join(dec)
}


function number_abbrev (number ) {
    let maxValue = 1000000;
    let sufix = '';
    let value = number;

    if(number > maxValue){
        sufix = 'k';
        value = number / 1000;
    }

    return number_format(value,2,',','.')+' '+sufix;
}


String.prototype.replaceAll = function(search, replacement) {
    var target = this;
    return target.split(search).join(replacement);
};


function hideModalInfo(modal){

    if(modal == 1) {
        $(".chart-legend .aer").hide();
        $('[class^="aer"]').hide();

        $(".aer-info").hide();
    }

    else if(modal == 2) {
        $(".chart-legend .mar").hide();
        $('[class^="mar"]').hide();
        $(".mar-info").hide();
    }
    else if(modal == 3) {
        $(".chart-legend .ter").hide();
        $('[class^="ter"]').hide();
        $(".ter-info").hide();
    }

}

function showModalInfo(modal){

    if(modal == 1) {
        $(".chart-legend .aer").show();
        $(".aer-info").show();
    }

    else if(modal == 2) {
        $(".chart-legend .mar").show();
        $(".mar-info").show();
    }
    else if(modal == 3) {
        $(".chart-legend .ter").show();
        $(".ter-info").show();
    }

}

function enableFilter(el){
    $(el).removeAttr('disabled');
    $(el).removeClass('not-allowed');


}

function disableFilter(el){
    $(el).attr('disabled','disabled');
    $(el).addClass('not-allowed');


}

function updateFilters(info){

    // Modals

    for (var modal in info.modals){
        if(info.modals[modal]){
            enableFilter($(".modalFilter label[value='"+modal+"']"));
            showModalInfo(modal);
        }else{
            disableFilter($(".modalFilter label[value='"+modal+"']"));
            hideModalInfo(modal);

        }
    }

    for (var operation in info.operations){
        if(info.operations[operation]){
            enableFilter($(".operationFilter input[value='"+operation+"']"));

        }else{
            disableFilter($(".operationFilter input[value='"+operation+"']"));

        }
    }

    for (var item in info.show){

        if(info.show[item]){
            $("."+item).show();

        }else{
            $("."+item).hide();

        }

    }

}

function setChartLabels(chart, labels) {
    chart.data.labels = labels;
}

function addOffersDonnutData(chart, approved, total) {

    approved = typeof approved !== 'undefined' ? approved : 0;
    total = typeof total !== 'undefined'  && total > 0 ? total : 100;

    chart.data.datasets = [];

    chart.update();


    percent = approved * 100 / total;



    let dataSet = {
        data: [percent,100 - percent],
        backgroundColor: [
            '#02bcd6',
        ],
        label: 'Ofertas',
        borderWidth: 0,
    };

    chart.config.options.elements.center.text = percent.toFixed(0)+"%";



    chart.data.datasets.push(dataSet);

    chart.update();
}


function addGaugeData(chart, approved, total, color) {

    var canvasID = $(chart.canvas).attr('id');
    $('.chart-label.'+canvasID).html('<i class="ti-lock"></i> '+approved +'/'+total);

    approved = typeof approved !== 'undefined' ? approved : 0;
    total = typeof total !== 'undefined'  && total > 0 ? total : 100;

    chart.data.datasets = [];

    chart.update();


    percent = approved * 100 / total;



    let dataSet = {
        data: [percent,100 - percent],
        backgroundColor: [
            color,
        ],
        label: '',
        borderWidth: 0,
    };

    chart.config.options.elements.center.text = percent.toFixed(0)+"%";


    chart.data.datasets.push(dataSet);

    chart.update();
}


function addPieData(chart, current, total, decimals) {
    decimals = (typeof decimals !== 'undefined') ? decimals : 0;

    var totalValue = current + total;
    var canvasID = $(chart.canvas).attr('id');




    var colors =  [
        '#FFD863',
        '#3AA6D0',
    ];

    current = typeof current !== 'undefined' ? current : 0;
    totalValue = typeof totalValue !== 'undefined'  && totalValue > 0 ? totalValue : 100;

    chart.data.datasets = [];

    chart.update();


    percent = current * 100 / totalValue;
    totalPercent = total * 100 / totalValue;


    $('.chart-label.'+canvasID+'_total').html(number_format(current + total, decimals,',','.'));

    if($('.chart-label.'+canvasID+'_opened').length > 0){
        $('.chart-label.'+canvasID+'_opened').html(number_format(current,decimals,',','.') + ' ('+(number_format(percent, decimals, ',', '.'))+'%)' );
    }

    if($('.chart-label.'+canvasID+'_boarded').length > 0){
        $('.chart-label.'+canvasID+'_boarded').html(number_format(total,decimals,',','.') + ' ('+(number_format(totalPercent, decimals, ',', '.'))+'%)' );
    }



    let labels = [
        number_format(current,decimals,',','.') ,
        number_format(total,decimals,',','.')

    ];

    if(percent == 0 && totalPercent == 0 ){
        colors = [];
        labels= [];
    }




    let dataSet = {
        data: [(percent.toFixed(2)),(100 - percent).toFixed(2)],
        backgroundColor: colors,
        label: labels,
        borderWidth: 0,
    };

    chart.config.options.elements.center.text = percent.toFixed(0)+"%";



    chart.data.datasets.push(dataSet);

    chart.update();
}


function addMultiplePieData(chart, data, labels, decimals) {
    decimals = (typeof decimals !== 'undefined') ? decimals : 0;

    var canvasID = $(chart.canvas).attr('id');

    chart.data.datasets = [];
    chart.update();

    var chartData = [];
    var colors = [];




    for (var index in data.values) {
        var value = data.values[index];
        chartData.push(value);
        colors.push(data.colors[index])
    }


    let dataSet = {
        data: chartData,
        backgroundColor: colors,
        label: labels,
        borderWidth: 0,
    };

    chart.data.datasets.push(dataSet);

    chart.update();



}



function addChartData(chart, dataSet) {
    chart.data.datasets.push(dataSet);
    chart.update();
}

function removeChartData(chart) {
    chart.data.labels = [];
    chart.data.datasets = [];

    chart.update();
}



function loadOffersData(chart, donnut, url, data) {


    $(".page-content").busyLoad("show", {
        animation: "fade",
        color: '#2c2f46',
        background: 'rgba(6, 6, 6, 0.05)',
        spinner: "cubes",
    });

    var totalOffers = 0;
    var totalApprovedOffers = 0;

    var totalAereo = 0;
    var totalMartimo = 0;
    var totalTerrestre = 0;
    var totalApprovedAereo = 0;
    var totalApprovedMar = 0;
    var totalApprovedTer = 0;



    $("#total_geral_abertos").html('-');
    $("#total_geral_aprovados").html('-');

    $("#total_air").html('-');
    $("#total_approved_air").html('-');

    $("#total_maritime").html('-');
    $("#total_approved_maritime").html('-');

    $("#total_land").html('-');
    $("#total_approved_land").html('-');

    $.ajax({
        data: data,
        dataType: 'json',
        url: url,
        success: function (data, textStatus, jqXHR) {
            $(chart.canvas).parent().parent().busyLoad("hide");

            if($('.top-nav a.active').data('type') == 'offers') {
                updateFilters(data.info);
            }
            setChartLabels(chart, data.labels);

            if(data.hasData) {

                for (var propertyName in data.dataset) {
                    let item = data.dataset[propertyName];

                    if(!!!item.show){
                        continue;
                    }

                    for (let valuePropertyName in item.data) {

                        let value = item.data[valuePropertyName];

                        // Filter modals

                        if (filters.modal.indexOf("1") <= -1 && propertyName.indexOf('_aer') !== -1) {
                            continue;
                        } else if (filters.modal.indexOf("2") <= -1 && propertyName.indexOf('_mar') !== -1) {
                            continue;
                        } else if (filters.modal.indexOf("3") <= -1 && propertyName.indexOf('_ter') !== -1) {
                            continue;
                        }

                        // filters operations
                        if (filters.operation.indexOf("1") <= -1 && propertyName.indexOf('exp_') !== -1) {
                            continue;
                        } else if (filters.operation.indexOf("2") <= -1 && propertyName.indexOf('imp_') !== -1) {
                            continue;
                        } else if (filters.operation.indexOf("3") <= -1 && propertyName.indexOf('nac_') !== -1) {
                            continue;
                        }

                        totalOffers += value.values.reduce((a, b) => a + b, 0);

                        if (valuePropertyName.indexOf('_approved') !== -1) {
                            totalApprovedOffers += value.values.reduce((a, b) => a + b, 0);
                        }

                        if (propertyName.indexOf('_aer') !== -1) {
                            totalAereo += value.values.reduce((a, b) => a + b, 0);

                            if (valuePropertyName.indexOf('_approved') !== -1) {
                                totalApprovedAereo += value.values.reduce((a, b) => a + b, 0);
                            }

                        } else if (propertyName.indexOf('_mar') !== -1) {
                            totalMartimo += value.values.reduce((a, b) => a + b, 0);


                            if (valuePropertyName.indexOf('_approved') !== -1) {
                                totalApprovedMar += value.values.reduce((a, b) => a + b, 0);
                            }
                        } else if (propertyName.indexOf('_ter') !== -1) {
                            totalTerrestre += value.values.reduce((a, b) => a + b, 0);
                            if (valuePropertyName.indexOf('_approved') !== -1) {
                                totalApprovedTer += value.values.reduce((a, b) => a + b, 0);
                            }
                        }


                        let dataSet = {
                            label: item.title,
                            attribute: value.label,
                            total: value.total,
                            backgroundColor: value.color,
                            stack: propertyName,
                            data: value.values,
                            borderWidth: 0,
                            segmentShowStroke: false,
                            borderColor: value.color,
                            borderSkipped: 'bottom',
                        };

                        if (!!!value.total) {
                            $(".chart-legend ." + propertyName + " .badge-point").css("background-color", value.color);
                        }

                        addChartData(chart, dataSet);


                    }
                }
            } else {

                $(chart.canvas).parent().parent().busyLoad("show", {
                     text: "Oops! Sem moviemntações para esta seleção",
                        spinner: false,
                        textColor: '#6c757d',
                        background: '#fff',
                });
            }


            addOffersDonnutData(donnut, totalApprovedOffers, totalOffers);

            $("#total_geral_abertos").html(totalOffers);
            $("#total_geral_aprovados").html(totalApprovedOffers);

            $("#total_air").html(totalAereo);
            $("#total_approved_air").html(totalApprovedAereo);

            $("#total_maritime").html(totalMartimo);
            $("#total_approved_maritime").html(totalApprovedMar);

            $("#total_land").html(totalTerrestre);
            $("#total_approved_land").html(totalApprovedTer);


            //
        },
        error: function (jqXHR, textStatus, errorThrown) {
            // When AJAX call has failed
            addOffersDonnutData(donnut, 0, 0);
        },
        complete: function () {
            $(".page-content").busyLoad("hide", {
                animation: "fade",
            });

        }
    });


}


function loadProcessData(chart, url, data) {


    //Clear labels

    $(".chart-label").html("&nbsp;");
    $(".air").addClass("not-allowed");
    $(".land").addClass("not-allowed");
    $(".maritime").addClass("not-allowed");


    $(".page-content").busyLoad("show", {
        animation: "fade",
        color: '#2c2f46',
        background: 'rgba(6, 6, 6, 0.05)',
        spinner: "cubes",
    });

    $.ajax({
        data: data,
        dataType: 'json',
        url: url,
        success: function (data, textStatus, jqXHR) {
            $(chart.canvas).parent().busyLoad("hide");
            if($('.top-nav a.active').data('type') == 'processes') {
                updateFilters(data.info);
            }
            setChartLabels(chart, data.labels);

            if(data.hasData) {

                for (var propertyName in data.dataset) {
                    let item = data.dataset[propertyName];


                    if(!!!item.show){
                        continue;
                    }

                    for (let valuePropertyName in item.data) {


                        let value = item.data[valuePropertyName];

                        // Filter Layers



                        if (filters.processes.indexOf("1") <= -1 && valuePropertyName.indexOf('opened') !== -1) {
                            continue;
                        } else if (filters.processes.indexOf("2") <= -1 && valuePropertyName.indexOf('boarded') !== -1) {
                            continue;
                        }

                        if(propertyName.indexOf('_mar') !== -1){
                            $('.maritime').removeClass('not-allowed');
                        }

                        if(propertyName.indexOf('_aer') !== -1){
                            $('.air').removeClass('not-allowed');
                        }

                        if(propertyName.indexOf('_ter') !== -1){
                            $('.land').removeClass('not-allowed');
                        }





                        let dataSet = {
                            label: item.title,
                            attribute: value.label,
                            total: value.total,
                            backgroundColor: value.color,
                            stack: propertyName,
                            data: value.values,
                        };


                        if(!!value.type) {
                            dataSet.type = value.type;
                            dataSet.fill = false;
                            dataSet.borderWidth = 3;
                            dataSet.lineTension = 0.1;
                            dataSet.borderColor = value.color;
                        }

                        if (!!!value.total) {
                            $(".chart-legend ." + propertyName + " .badge-point").css("background-color", value.color);
                        }

                        addChartData(chart, dataSet);


                    }
                }
            } else {

                $(chart.canvas).parent().busyLoad("show", {
                    text: "Oops! Sem moviemntações para esta seleção",
                    spinner: false,
                    textColor: '#6c757d',
                    background: '#fff',
                });
            }

            if(data.total) {

                if (data.total.mar) {
                    addGaugeData(insuranceMaritimeToBoardGauge, data.total.mar.openedInsure, data.total.mar.opened, data.dataset.exp_mar.data.boarded.color);
                    addGaugeData(insuranceMaritimeBoardedGauge, data.total.mar.insured, data.total.mar.boarded, data.dataset.imp_mar.data.boarded.color);
                    addPieData(boardedVsToBoardMaritimeToBoardPie, data.total.mar.toBoard, data.total.mar.boarded);
                } else {
                    addGaugeData(insuranceMaritimeToBoardGauge, 0, 0);
                    addGaugeData(insuranceMaritimeBoardedGauge, 0, 0);
                    addPieData(boardedVsToBoardMaritimeToBoardPie, 0, 0);
                }


                if (data.total.aer) {
                    addGaugeData(insuranceAirToBoardGauge, data.total.aer.openedInsure, data.total.aer.opened, data.dataset.exp_aer.data.boarded.color);
                    addGaugeData(insuranceAirBoardedGauge, data.total.aer.insured, data.total.aer.boarded, data.dataset.imp_aer.data.boarded.color);
                    addPieData(boardedVsToBoardAirToBoardPie, data.total.aer.toBoard, data.total.aer.boarded);
                } else {
                    addGaugeData(insuranceAirToBoardGauge, 0, 0);
                    addGaugeData(insuranceAirBoardedGauge, 0, 0);
                    addPieData(boardedVsToBoardAirToBoardPie, 0, 0);
                }

                if (data.total.ter) {
                    addGaugeData(insuranceLandToBoardGauge, data.total.ter.openedInsure, data.total.ter.opened, data.dataset.exp_ter.data.boarded.color);
                    addGaugeData(insuranceLandBoardedGauge, data.total.ter.insured, data.total.ter.boarded, data.dataset.imp_ter.data.boarded.color);
                    addPieData(boardedVsToBoardLandToBoardPie, data.total.ter.toBoard, data.total.ter.boarded);
                } else {
                    addGaugeData(insuranceLandToBoardGauge, 0, 0);
                    addGaugeData(insuranceLandBoardedGauge, 0, 0);
                    addPieData(boardedVsToBoardLandToBoardPie, 0, 0);
                }
            }

        },
        error: function (jqXHR, textStatus, errorThrown) {
            // When AJAX call has failed
            addOffersDonnutData(donnut, 0, 0);
        },
        complete: function () {
            $(".page-content").busyLoad("hide", {
                animation: "fade",
            });


        }
    });


}


function loadVolumesData(chart, url, data) {


    //Clear labels

    $(".chart-label").html("&nbsp;");
    $(".air").addClass("not-allowed");
    $(".land").addClass("not-allowed");
    $(".maritime").addClass("not-allowed");


    $(".page-content").busyLoad("show", {
        animation: "fade",
        color: '#2c2f46',
        background: 'rgba(6, 6, 6, 0.05)',
        spinner: "cubes",
    });

    $.ajax({
        data: data,
        dataType: 'json',
        url: url,
        success: function (data, textStatus, jqXHR) {
            $(chart.canvas).parent().busyLoad("hide");

            if($('.top-nav a.active').data('type') == 'volumes') {
                updateFilters(data.info);
            }

            setChartLabels(chart, data.labels);

            if(data.hasData) {

                for (var propertyName in data.dataset) {
                    let item = data.dataset[propertyName];


                    if(!!!item.show){
                        continue;
                    }

                    for (let valuePropertyName in item.data) {


                        let value = item.data[valuePropertyName];

                        // Filter Layers

                        if(propertyName.indexOf('_mar') !== -1){
                            $('.maritime').removeClass('not-allowed');
                        }

                        if(propertyName.indexOf('_aer') !== -1){
                            $('.air').removeClass('not-allowed');
                        }

                        if(propertyName.indexOf('_ter') !== -1){
                            $('.land').removeClass('not-allowed');
                        }





                        let dataSet = {
                            label: item.title,
                            attribute: value.label,
                            total: value.total,
                            backgroundColor: value.color,
                            stack: propertyName,
                            data: value.values,
                        };


                        if(!!value.type) {
                            dataSet.type = value.type;
                            dataSet.fill = false;
                            dataSet.borderWidth = 3;
                            dataSet.lineTension = 0.1;
                            dataSet.borderColor = value.color;
                        }

                        if (!!!value.total) {
                            $(".chart-legend ." + propertyName + " .badge-point").css("background-color", value.color);
                        }

                        addChartData(chart, dataSet);


                    }
                }
            } else {

                $(chart.canvas).parent().busyLoad("show", {
                    text: "Oops! Sem moviemntações para esta seleção",
                    spinner: false,
                    textColor: '#6c757d',
                    background: '#fff',
                });
            }

            if(data.total) {

                if (data.total.mar) {

                    addPieData(volumesBoardedVsToBoardMaritimeToBoardPie, data.total.mar.toBoard, data.total.mar.boarded, 2);
                } else {
                    addPieData(volumesBoardedVsToBoardMaritimeToBoardPie, 0, 0, 2);
                }


                if (data.total.aer) {

                    addPieData(volumesBoardedVsToBoardAirToBoardPie, data.total.aer.toBoard, data.total.aer.boarded, 2);
                } else {

                    addPieData(volumesBoardedVsToBoardAirToBoardPie, 0, 0, 2);
                }

                if (data.total.ter) {
                    addPieData(volumesBoardedVsToBoardLandToBoardPie, data.total.ter.toBoard, data.total.ter.boarded, 2);
                } else {
                    addPieData(volumesBoardedVsToBoardLandToBoardPie, 0, 0, 2);
                }
            }

        },
        error: function (jqXHR, textStatus, errorThrown) {
            // When AJAX call has failed
            // addOffersDonnutData(donnut, 0, 0);
        },
        complete: function () {
            $(".page-content").busyLoad("hide", {
                animation: "fade",
            });


        }
    });


}


import Http from '@/services/Http'
import BaseService from '@/services/BaseService'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import httpBuildQuery from 'http-build-query'

dayjs.extend(customParseFormat)
const urlAPI = process.env.VUE_APP_APIENDPOINT

export const KpiService = {
  getShippingWweight (idCustomer, period, filter) {
    let queryString = httpBuildQuery(filter)
    period = period || 'weekly'

    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/customer/${period}/shipping-weight?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  // Valor do frete da oferta X processo
  // localhost/app_dev.php/api/v2/customer/11/process-offers-freight-price/weekly
  // localhost/app_dev.php/api/v2/customer/{customer}/process-offers-freight-price/{period}
  getValueFreightPrice (idCustomer, period, origin, destination, beginDate, endDate) {
    period = period || 'weekly'
    origin = origin || ''
    destination = destination || ''
    beginDate = beginDate || null
    endDate = endDate || null

    let dayParse = 'DD/MM/YYYY'

    beginDate = dayjs(beginDate, dayParse).isValid() ? dayjs(beginDate, dayParse).format('YYYY-MM-DD') : ''
    endDate = dayjs(endDate, dayParse).isValid() ? dayjs(endDate, dayParse).format('YYYY-MM-DD') : ''
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/customer/${idCustomer}/process-offers-freight-price/${period}?origin=${origin}&destination=${destination}&beginDate=${beginDate}&endDate=${endDate}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  // Média por origem e destino
  // localhost/app_dev.php/api/v2/customer/11/freight/weekly/origin/destination
  // localhost/app_dev.php/api/v2/customer/{customer}/freight/{period}/origin/destination
  getValueFreight (idCustomer, period, filter) {
    let queryString = httpBuildQuery(filter)
    period = period || 'weekly'

    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/customer/${period}/freight/origin/destination?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  getValuePaymentTime (idCustomer) {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/customer/${idCustomer}/payment-time`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  getInsightsTimeRelease (idCustomer) {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/customer/${idCustomer}/insight-time-release`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  /**
   * http://localhost/api/V1/customer/11/origins/monthly/destinations
   *
   * @param idCustomer
   * @param period
   * @returns {Promise<any>}
   */
  getOriginDestinations (idCustomer, period, filter) {
    let queryString = httpBuildQuery(filter)
    period = period || 'monthly'

    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/customer/origins/destinations?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  /**
   * http://localhost/api/V1/customer/11/consignees/monthly/shippers
   *
   * @param idCustomer
   * @param period
   * @returns {Promise<any>}
   */
  getConsigneesShippers (idCustomer, period, filter) {
    let queryString = httpBuildQuery(filter)
    period = period || 'weekly'

    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/customer/consignees/shippers?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  /**
   * http://localhost/api/V2/customer/11/invoices/monthly
   *
   * @param idCustomer
   * @returns {Promise<any>}
   */
  getInvoices (idCustomer, period, filter) {
    period = period || 'weekly'
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/customer/${idCustomer}/invoices/${period}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  /**
   * http://localhost/api/V2/customer/11/processes/monthly
   *
   * @param idCustomer
   * @returns {Promise<any>}
   */
  getProcesses (idCustomer, period, filter) {
    let queryString = httpBuildQuery(filter)
    period = period || 'weekly'
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/customer/${period}/processes?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  /**
   * http://localhost/api/V1/customer/11/volumes/monthly
   *
   *
   * @param idCustomer
   * @returns {Promise<any>}
   */
  getVolumes (idCustomer, period, filter) {
    let queryString = httpBuildQuery(filter)
    period = period || 'weekly'

    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/customer/${period}/volumes?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  /**
   * http://localhost/api/V1/customer/11/origins/monthly/destinations
   *
   * @param idCustomer
   * @param period
   * @returns {Promise<any>}
   */
  getMajorCarries  (idCustomer, period, filter) {
    let queryString = httpBuildQuery(filter)
    period = period || 'monthly'

    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/customer/major/carries?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getMajorCarriers () {},
  getAnalysts () {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/kpi/analyst`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getAnalystsData (analystIds, period) {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/kpi/id=${analystIds}&month=${period}/analyst/data`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getTeamStatistics (setorId, period) {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/kpi/id=${setorId}&month=${period}/team/data`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getOperationalList (setorId, period) {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/kpi/operational/data`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getOperationalActivityList (period) {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/kpi/month=${period}/operational/activities`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getProcessActivityList (period) {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/kpi/month=${period}/process/activities`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getProcessCancelList (period) {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/kpi/month=${period}/process/cancel`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getActivityTransferList (period) {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/kpi/month=${period}/activities/transfer`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  }
}

export default KpiService
